import React from 'react'
import { useNavigate } from 'react-router-dom'


function Unauthorized() {
  const navigate = useNavigate()
  const goBack = ()=>navigate(-1)
  return (
    <div className='divCenter'>
      <div>

      <h1>Unauthorized</h1>
      <br/>
        <p>You do not have access to requested page!</p>
      <div className='flexGrow'>
        <button onClick={goBack}> Go Back</button>
      </div>
      </div>
    </div>
  )
}

export default Unauthorized