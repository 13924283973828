import { useEffect } from 'react'
import {privateAxios} from '../plugins/ApiClient'
import useAuth from './useAuth'
import useRefreshToken from './useRefreshToken'



const useAxiosPrivate = ()=>{
    const refresh = useRefreshToken()
    const {auth} = useAuth()
    
    useEffect(()=>{
        const requestInterceptor = privateAxios.interceptors.request.use(
            config =>{
                if(!config.headers['x-auth-token']){
                    config.headers['x-auth-token'] = auth?.accessToken
                }
                // console.log(`useAxiosprivate requestInterceptor config: ${JSON.stringify(config)}`);
                return config;
            },(error)=> Promise.reject(error)
        )
        const responseInterceptor = privateAxios.interceptors.response.use(
            response => response,
            async(error)=>{
                const prevRequest = error?.config
                if(error?.response?.status === 401 & !prevRequest?.sent){
                    prevRequest.sent = true;
                    const newAccessToken = await refresh();
                    prevRequest.headers['x-auth-token'] = newAccessToken
                    return privateAxios(prevRequest);
                }
                return Promise.reject(error)
            }
        )
        return()=>{
            privateAxios.interceptors.request.eject(requestInterceptor)
            privateAxios.interceptors.response.eject(responseInterceptor)
        }
    },[auth, refresh])
    return privateAxios
}   

export default useAxiosPrivate
