import React, { useEffect, useMemo, useState } from 'react'
import DataTable from 'react-data-table-component'
import { useNavigate, useLocation } from 'react-router-dom'



function DataTableComponent({ columns, data, onModalOpen }) {
  const tColumns = useMemo(()=> columns ? columns : [])
  const tData = useMemo(()=>data ? data : [])
  const [filteredData, setFilteredData] = useState([]) 
  const handleSearch = (e)=>{setFilteredData(tData.filter(r =>  r.name.toLowerCase().includes(e.target.value.toLowerCase())))}
  useEffect(()=>{
    setFilteredData(tData)
  },[tData])
  
  
  
  const customStyles = {
    headRow: {
      style: {
        border: 'none',
      },
    },
    headCells: {
      style: {
        color: '#202124',
        fontSize: '14px',
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: 'rgb(230, 244, 244)',
        borderBottomColor: '#FFFFFF',
        borderRadius: '25px',
        outline: '1px solid #FFFFFF',
      },
    },
    pagination: {
      style: {
        border: 'none',
      },
    },
  };

  
  return (
    <>
      <div className='  flex justify-between items-center w-full mr-10'>
        <input type="text" className="mt-[.5rem] max-w-60" placeholder="Search" onChange={handleSearch}/>
        <button className='px-3 py-2   top-30 right-20' onClick={() => onModalOpen('create')}><span className='mr-2'>➕</span>Create Location</button>
      </div>
      <DataTable columns={tColumns} data={filteredData} highlightOnHover
        pointerOnHover customStyles={customStyles} pagination ></DataTable>
    
    </>
  )
}

export default DataTableComponent