import React, { useEffect, useState } from 'react';

import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const CrudModal = ({ show, handleClose, title }) => {
  const axiosPrivate = useAxiosPrivate()
  const [locations, setLocations] = useState([])
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    username: '',
    pass: '',
    fcn: '',
    location: ''
  });
  useEffect(()=>{
    setLocations(axiosPrivate.post('api/locations',{query:{status:1}}))
  },[])
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    // Handle form submission here, e.g., send data to server
    axiosPrivate.post('api/user', formData)
    handleClose(); // Close the modal after submission
  };

  return (
    <div className={`fixed inset-0 overflow-y-auto ${show ? 'block' : 'hidden'} z-50` }>
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center block">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full py-5">
                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">{title}</h3>
                <form>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Card No</label>
                    <input type="text" name="fcn" value={formData.fcn} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">First Name</label>
                    <input type="text" name="fname" value={formData.fname} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Last Name</label>
                    <input type="text" name="lname" value={formData.lname} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">User Name</label>
                    <input type="text" name="username" value={formData.username} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">password</label>
                    <input type="text" name="pass" value={formData.pass} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">First Name</label>
                    <select name="location" value={formData.location} onChange={handleChange} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                      <option value="">Select a location</option>
                      {locations?.map((location) => (
                        <option key={location} value={location}>
                          {location}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* Repeat for other form fields */}
                </form>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button onClick={handleSubmit} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">Save Changes</button>
            <button onClick={handleClose} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">Save Changes</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrudModal;
