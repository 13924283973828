import './App.css';
import Layout from './components/Layout/Layout';
import RequireAuth from './components/RequireAuth';
import Login from './pages/Login'

import { Routes,Route } from 'react-router-dom';
import Missing from './pages/Missing';
import Unauthorized from './pages/Unauthorized';
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings';
import { ContextProvider } from './contexts/ContextProvider';
import Users from './pages/Users';
import PersisLogin from './components/PersisLogin';
import LocationPage from './pages/locations/LocationPage';


function App() {
  return (
        
        <ContextProvider>
          <Routes>
           
              
              <Route path='login' element={<Login/>} />
              <Route path='unauthorized' element={<Unauthorized/>}/>
                <Route element={<PersisLogin/>}>
                  <Route element={<RequireAuth allowedRole={"2024"}/>}>
                    <Route path='/' element={<Layout/>}> 
                        <Route path='dashboard' element={<Dashboard/>}/>
                        <Route path='users' element={<Users/>}/>
                        <Route path='locations' element={<LocationPage/>}/>
                         <Route path='unauthorized' element={<Unauthorized/>}/>
                    </Route>
                  </Route>
                <Route element={<RequireAuth allowedRole={"5154"}/>}>
                  <Route path='settings' element={<Settings/>}/>
                </Route>
                </Route>
            

              <Route path='*' element={<Missing/>}/>
              
          </Routes>
        </ContextProvider>
           
  );
}

export default App;
