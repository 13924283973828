import { useEffect, useRef, useState } from "react"


import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import { useLocation, useNavigate } from "react-router-dom"
import PageHeader from "../../components/PageHeader"
import DataTableComponent from "../../components/DataTableComponent"
import CrudModal from "../../components/user/userCrudForm"
import LocationModal from "./LocationModal"
import { privateAxios } from "../../plugins/ApiClient"
import { NAME_REGEX,ORDER_REGEX } from "../../statics/regex"
import LocationForm from "./LocationForm"



function LocationsPage() {
    const [formData, setFormData] = useState({
        name: '',
        order: '',
        status: '',

    });
    
    const [validName, setValidName] = useState(false);
    const [nameFocus, setNameFocus] = useState(false);

    const [validOrder, setValidOrder] = useState(false);
    const [orderFocus, setOrderFocus] = useState(false);

    const [validStatus, setValidStatus] = useState(false);
    const [statusFocus, setStatusFocus] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [locations, setLocations ] = useState([])
    const [selectedLocation, setSelectedLocation] = useState([])
    

    const nameRef = useRef()
    const orderRef = useRef()
    const statusRef = useRef()
    const errRef = useRef()
    const [errMsg, setErrMsg] = useState('');
    
    
    const axiosPrivate = useAxiosPrivate()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => { nameRef.current.focus() }, [])
    useEffect(() => {
        fetchLocations()
    }, [])

    useEffect(() => {
        setValidName(NAME_REGEX.test(formData.name));
        setValidOrder(ORDER_REGEX.test(formData.order))
    }, [formData.name,formData.order])

    useEffect(() => { setErrMsg('') }, [formData?.name,formData?.order,formData?.status])
   

    const fetchLocations = async () => {
        try {
            const response = await axiosPrivate.post('/api/locations', { query: { status: 1 } })
            setLocations(response.data.data)
        } catch (error) {
            console.error(error)
            navigate('/login', { state: { from: location }, replace: true })
        }
    }
    const handleModalOpen = (type, location = null) => {
        
        setModalType(type);
        setSelectedLocation(location);
        setFormData({...formData,
            'name':location?.name ? location.name : '',
            'order':location?.order ? location.order : '',
            'status': location?.status ? location.status : ''
        })
        setShowModal(true);
    };
    const onDelete = async () => {
        try {
            const response = await privateAxios.post('api/location/destroy/' + selectedLocation.id)
            if (response.status === 200) {
                setShowModal(false);
                fetchLocations()
            }
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response')
            } else if (err.response.status === 400) {
                setErrMsg(err.response.data.message)
            } else if (err.response.status === 406) {
                setErrMsg(err.response.data.message)
            } else if (err.response.status === 401) {
                setErrMsg('Unauthorized')
            } else {
                setErrMsg('Login falied!')
            }
            // errRef.current.focus()
        }
    }
    const onSubmit = async() => {
        if (modalType === 'create'){
            try {
                const response = await privateAxios.post('api/location', formData)
                if(response.status === 200){
                    setShowModal(false);
                    fetchLocations()
                    return
                }
            } catch (err) {
                if (!err?.response) {
                    setErrMsg('No Server Response')
                } else if (err.response.status === 400) {
                    setErrMsg(err.response.data.message)
                } else if (err.response.status === 406) {
                    setErrMsg(err.response.data.message)
                } else if (err.response.status === 401) {
                    setErrMsg('Unauthorized')
                } else {
                    setErrMsg('Login falied!')
                }
                errRef.current.focus()
                return
            }
        }
        try {
            const response = await privateAxios.post('api/location/update/' + selectedLocation.id,formData)
            if (response.status === 200) {
                setShowModal(false);
                fetchLocations()
            }
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response')
            } else if (err.response.status === 400) {
                setErrMsg(err.response.data.message)
            } else if (err.response.status === 406) {
                setErrMsg(err.response.data.message)
            } else if (err.response.status === 401) {
                setErrMsg('Unauthorized')
            } else {
                setErrMsg('Login falied!')
            }
            errRef.current.focus()
        }
    }

    const handleModalClose = () => {
        setShowModal(false);
        setSelectedLocation(null);
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const columns = [
        {
            name: 'Order',
            selector: row => row.order
        },
        {
            name: 'name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
           

        },
        {
            cell:(row)=>
                <>
                <button className="mr-3" onClick={() => handleModalOpen('read', row)}>📂</button>
                    <button className="mr-3"  onClick={() => handleModalOpen('update', row)}>✏️</button>
                    <button className="bg-red-600"  onClick={() => handleModalOpen('delete', row)}><span className="">🗑️</span></button>
                </>
            
        }
        
    ]
    const view = (<div>{selectedLocation?.name}</div>)
   
    
    return (
        <section>
            <PageHeader title={'List of App Users'} />
            <DataTableComponent columns={columns} data={locations} onModalOpen={handleModalOpen} />
            <LocationModal
                show={showModal}
                onClose={handleModalClose}
                type={modalType}
                selectedItem={selectedLocation}
                handleChange={handleChange}
                formData={formData}
                onDelete={onDelete}
                onSubmit={onSubmit}
                view={view}
                form={LocationForm(errRef, errMsg, orderRef, formData, handleChange, validOrder, setOrderFocus, orderFocus, nameRef, validName, nameFocus, setNameFocus)}
            />
        </section>
    )
}

export default LocationsPage