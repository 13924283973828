import axios from "axios";




export default axios.create({
    // https://linguistan.org/api/auth/login
    // baseURL:'http://localhost:5000/api/',
    baseURL:' https://linguistan.org/api/',
   headers:{'Content-Type':'application/json'},
    withCredentials:true
})

export const privateAxios =  axios.create({
    // baseURL:'http://localhost:5000/',
    baseURL:'https://linguistan.org/',
    headers:{'Content-Type':'application/json'},
    withCredentials:true
})

