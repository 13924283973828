import {useRef,useState,useEffect} from 'react'
import axios from '../plugins/ApiClient'
import useAuth from '../hooks/useAuth'
import { Link,useNavigate,useLocation } from 'react-router-dom'

function Login() {
  const {setAuth,auth, persist, setPersist} = useAuth() 

  const navigate = useNavigate()
  const location = useLocation()
  const from = location?.state?.from?.pathname || "/"


  const userRef=useRef() 
  const errRef=useRef()
  
  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');
  

  useEffect(()=>{userRef.current.focus()},[])
  useEffect(()=>{setErrMsg('')},[user,pwd])
  const handleSubmit = async(e)=>{
    e.preventDefault();
    try {
      const response = await axios.post('auth/login',{username:'amir.nazir',password:'password'})//{username:user,password:pwd})
      const accessToken = response?.data?.accessToken
      const accesCode = response?.data?.accesCode
      setAuth({user,pwd,accesCode,accessToken})
      setUser('')
      setPwd('')
      // console.log('context:',auth)
      navigate(from,{replace:true})
    } catch (err) {
      if(!err?.response){
        setErrMsg('No Server Response')
      } else if(err.response.status === 400 ){
        setErrMsg('username or password is missing!')
      } else if(err.response.status === 401){
        setErrMsg('Unauthorized')
      }else{
        setErrMsg('Login falied!')
     }
      errRef.current.focus()
    }
    
  }
  const togglePersist = ()=>{
    setPersist(prev=>!prev)
  }
  useEffect(()=>{
    localStorage.setItem('persist',persist)
  },[persist])
  return (  
      <div className='divCenter' >
        <section className='center formCard'>
          <h1 className='headingFont'>Sign in</h1>
          <p ref={errRef} className={errMsg ? 'errmsg':'offscreen'} aria-live='assertive'>{errMsg}</p>
          <form onSubmit={handleSubmit}>
            <lable htmlFor='username'>Username: </lable>  
            <input id='username' type='text' ref={userRef} autoComplete='off' onChange={e=>setUser(e.target.value)} value={user} required/>
            
            <lable htmlFor='password'>Password: </lable>  
            <input id='password' type='password'  autoComplete='off' onChange={e=>setPwd(e.target.value)} value={pwd} required/>

            <div>
              <input type='checkbox' id='persist' onChange={togglePersist} checked={persist}/>
              <label htmlFor='persist'> Remember me</label> 
            </div>
            <button >Sign in</button>
          </form>  
        </section>
      </div>
   
  )
}

export default Login