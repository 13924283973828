import React, { useEffect, useState } from 'react';
const LocationModal = ({ show, onClose, type,selectedItem,handleChange,formData,onDelete,onSubmit,view,form }) => {
    return (
        <div className={`fixed inset-0 overflow-y-auto ${show ? 'block' : 'hidden'} z-50`}>
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center block">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full py-5">
                                <div className='flex justify-between items-center'>
                                    <h5 className="font-medium text-[24px]">{type === 'create' ? 'Create Location' : type === 'update' ? 'Edit Location' : type === 'delete' ? 'Delete Location' : 'Location Details'}</h5>
                                    <button type="button"  aria-label="Close" onClick={onClose}>❌</button>
                                </div>
                                {
                                    type === 'read' 
                                    ? (
                                     view
                                    )  
                                    : type === 'delete' 
                                    ?(
                                        <div> are you sure!</div>
                                    ) 
                                    :(
                                        form
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        {type === 'delete' ? (
                            <button onClick={onDelete} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">Delete</button>
                        ) : (
                            <button onClick={onSubmit} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">{type === 'create' ? 'Create' : 'Save Changes'}</button>
                        )}
                        <button onClick={onClose} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">Cancel</button>

                        {/* <button onClick={handleSubmit} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">Save Changes</button>
                        <button onClick={handleClose} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">Cancel</button> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LocationModal;
