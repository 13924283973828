import { Outlet } from "react-router-dom";

import React, { useEffect, useState } from 'react'
import useRefreshToken from '../hooks/useRefreshToken'
import useAuth from "../hooks/useAuth";


function PersisLogin() {
    const [isLoading, setIsLoading] = useState(true)
    const refresh = useRefreshToken()
    const {auth, persist} = useAuth()

    useEffect(()=>{
        const verifyRefreshToken = async()=>{
            try {
                await refresh();
            } catch (error) {
                console.error(error)
            } finally {
                setIsLoading(false)
            }
        }

        !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false)

    },[])

    
  return (
    <>
        {
        !persist ? 
        <Outlet/> : 
        isLoading ? 
        <p>Loading....</p> : <Outlet/>}
    </>
  )
}

export default PersisLogin