import React from 'react';

const Footer = () => (
  <div >
    <p className="dark:text-gray-200 text-gray-700 text-center">
      © 2022 All rights reserved by Shoppy.com
    </p>
  </div>
);

export default Footer;
