import {privateAxios} from "../plugins/ApiClient"
import useAuth from "./useAuth"


const  useRefreshToken=()=> {
const {setAuth}  = useAuth()
    const refresh = async ()=>{
        const response =  await privateAxios.post('refresh',{
            withCredentional:true
        })
        setAuth(prev =>{
            return {
                ...prev, 
                accesCode:response.data.accesCode, 
                accessToken: response.data.accessToken

            }
        })
        return response.data.accessToken
    }
    return refresh

}


export default useRefreshToken