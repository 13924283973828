import {  useEffect, useState } from "react"

import useAuth from "../hooks/useAuth"
import { useStateContext } from "../contexts/ContextProvider"
import useAxiosPrivate from "../hooks/useAxiosPrivate"
import { useLocation, useNavigate } from "react-router-dom"
import PageHeader from "../components/PageHeader"
import DataTableComponent from "../components/DataTableComponent"
import Modal from "../components/Modal"
import CrudModal from "../components/user/userCrudForm"


function  Users  () {
    const {users, setUsers} = useStateContext()
    const axiosPrivate = useAxiosPrivate()
    const navigate = useNavigate()
    const location = useLocation()
    const [edit, setEdit] = useState(false)
    const [modalOpen, setModalOpen] = useState(false);
   
    useEffect(()=>{
        let isMounted = true;
        const controller = new AbortController();

        const getUsers = async()=>{
        try {
            const response = await axiosPrivate.post('/api/users',{query:{status:1}})
            // console.log(`getUsers from user page:${response.data.data}`);
            isMounted && setUsers(response.data.data)
        } catch (error) {
            console.error(error)
            navigate('/login',{state:{from:location}, replace:true})
        }
    }

            getUsers()
        return()=>{ 
            isMounted = false
            controller.abort()
        }
    },[])
const columns = [
    {
        name:'Card No',
        selector: row => row.fcn
    },
    {
        name:'User Name',
        selector: row => row.username,
        sortable: true
    },
    {
        name:'Status',
        selector: row => row.approval,
        sortable: true,
        conditionalCellStyles: [
			{
				when: row => row.approval === "padding",
				classNames: ['statusPendding'],
			},
			{
				when: row => row.approval === "approved" && row.calories < 400,
				classNames: ['statusApproved'],
			},
			
		],
        
    },
    {
        name:'Full Name',
        selector: row => row.fname +' '+ row.lname
    },
     {
        name:'Location',
        selector: row => row.location?.data?.name,
        sortable: true
    },
]
  return (
      <section>
        <CrudModal show={modalOpen} handleClose={()=>setModalOpen(false)} locations={['Iqbal town', 'Chasma town,', 'I-9', 'Zia Masjid']}/>
        <PageHeader title={'List of App Users'}/>
        <DataTableComponent columns={columns} data={users} edit={setModalOpen} />
    </section>
  )
}

export default Users