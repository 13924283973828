import { useLocation, Outlet, Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = ({allowedRole})=>{
    const {auth} = useAuth()
    const location = useLocation()
    return (
        auth?.accesCode === allowedRole
        ? <Outlet/>
        : auth?.user
        ? <Navigate to='/unauthorized' state={{from:location}} replace/>
        : <Navigate to='/login' state={{from: location}} replace/>
    )
}

export default RequireAuth
