import { useEffect } from 'react'
import { Navbar, Footer, Sidebar, ThemeSettings } from '../../components';
import {  Routes, Route } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';

import { Outlet } from 'react-router-dom'
function Layout() {
  const { setCurrentColor, setCurrentMode, currentMode, activeMenu, currentColor, themeSettings, setThemeSettings } = useStateContext();
    useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);
  return (
   <div className={currentMode === 'Dark' ? 'dark' : ''}>
     
        <div className="flex relative dark:bg-main-dark-bg">
        
          {activeMenu ? (
            <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg barBackgroud ">
              <Sidebar />
            </div>
          ) : (
            <div className="w-0 dark:bg-secondary-dark-bg">
              <Sidebar />
            </div>
          )}
          <div
            className={
              activeMenu
                ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  '
                : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
            }
          >
            <div className="fixed md:static navbar w-full barBackgroud ">
              <Navbar />
            </div>
            <div className='pageLayout'>
              {/* {themeSettings && (<ThemeSettings />)} */}
            <Outlet/>
             
            </div>
            
            <Footer  />
          </div>
        </div>
      
    </div>
  )
}

export default Layout