import React from 'react'

function LocationForm(errRef,errMsg,orderRef,formData,handleChange,validOrder,setOrderFocus,orderFocus,nameRef,validName,nameFocus,setNameFocus) {
  return (
    
          <div>
              <p ref={errRef} className={errMsg ? 'errmsg' : 'offscreen'} aria-live='assertive'>{errMsg}</p>
              <form>
                  <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">Order</label>
                      <input
                          type="text"
                          name="order"
                          ref={orderRef}
                          value={formData?.order}
                          onChange={handleChange}
                          required
                          aria-invalid={validOrder ? "false" : "true"}
                          aria-describedby="ordernote"
                          onFocus={() => setOrderFocus(true)}
                          onBlur={() => setOrderFocus(false)}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
                      <p id="ordernote" className={orderFocus && formData.order && !validOrder ? "instructions" : "offscreen"}>
                          1 to 100 digits are allowed.<br />
                          Only numbers allowed.
                      </p>
                  </div>
                  <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">name</label>
                      <input
                          type="text"
                          name="name"
                          ref={nameRef}
                          value={formData?.name}
                          onChange={handleChange}
                          required
                          aria-invalid={validName ? "false" : "true"}
                          aria-describedby="namenote"
                          onFocus={() => setNameFocus(true)}
                          onBlur={() => setNameFocus(false)}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
                      <p id="namenote" className={nameFocus && formData.name && !validName ? "instructions" : "offscreen"}>
                          4 to 24 characters.<br />
                          Must begin with a letter.<br />
                          Letters, numbers, underscores, hyphens allowed.
                      </p>
                  </div>
                  <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">Status</label>
                      <div className="mt-1">
                          <input
                              type="radio"
                              name="status"
                              value={1}
                              checked={formData.status === 1 ? true : null}
                              onClick={handleChange}
                              className="mr-2"
                          />
                          <label htmlFor="option1" className="mr-4">Active </label>
                          <input
                              type="radio"
                              name="status"
                              value={0}
                              checked={formData.status === 0 ? true : null}
                              onChange={handleChange}
                              className="mr-2"
                          />
                          <label htmlFor="option2">In-Active</label>
                      </div>
                  </div>
                  {/* Repeat for other form fields */}
              </form>
          </div>
      )
 
}

export default LocationForm