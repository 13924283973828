import React from 'react'

function PageHeader({title}) {
  return (
    <div>
          <h2>{title}</h2>
    </div>
  )
}

export default PageHeader