import React from 'react'
import { useNavigate } from 'react-router-dom'


function Missing() {
  const navigate = useNavigate()
  const goBack = ()=>navigate(-1)
  return (
    <div className='divCenter'>
      <div>

      <h1>Not Found </h1>
      <br/>
        <p>Page you are looking for dose not exist!</p>
      <div className='flexGrow'>
        <button onClick={goBack}> Go Back</button>
      </div>
      </div>
    </div>
  )
}

export default Missing